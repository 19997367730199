.custom-cascader .cascader-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .custom-cascader .cascader-dropdown {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 60%;
    background-color: var(--phoenix-emphasis-bg);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
  }
  
  .cascader-menus {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  
  .cascader-menu {
    flex: 1;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    overflow: auto;
  }
  
  .cascader-menu:last-child {
    border-bottom: none;
  }
  
  .cascader-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .cascader-menu ul li {
    padding: 8px;
    cursor: pointer;
  }
  
  .cascader-menu ul li:hover,
  .cascader-menu ul li.selected {
    background-color: var(--phoenix-secondary-bg);
  }
  
  /* Landscape (keep the menus side by side) */
  @media (orientation: landscape) {
    .custom-cascader .cascader-dropdown {
      flex-direction: row;
      width: 60%;
      height: 50%;
    }
  
    .cascader-menus {
      flex-direction: row;
    }
  
    .cascader-menu {
      border-bottom: none;
      border-right: 1px solid #ccc;
    }
  
    .cascader-menu:last-child {
      border-right: none;
    }
  }
  